import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
/**
 * service to pass arbitary data between routes 
 */
export class NavigationStateService {

  private nextNavId: number = 1;

  private stateMap: Map<number, any> = new Map<number, any>();

  constructor() { }

  /**
   * 
   * @param state data to save for naviagtion
   */
  public saveNavigationState(state: any): number {
    let navId = this.nextNavId;
    this.nextNavId++;
    this.stateMap.set(navId, state);

    return navId;
  }

  /**
   * 
   * @param navId navigation id returned by @this.saveNavigationState
   */
  public getNavigationState(navId: number): any {
    let state = this.stateMap.get(navId);
    this.stateMap.delete(navId);

    return state;
  }
}
