import { CharacterLimitPipe } from './characterLimit.pipe';
import { NgModule} from '@angular/core';

@NgModule({
    imports: [],
    declarations: [CharacterLimitPipe],
    exports: [CharacterLimitPipe]
})

export class CharacterLimitModule {

}