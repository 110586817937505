import {UserBean, UserData} from '../models/userbean';


export class actionItemModelRequestH {
    userBean: UserBean;
    userData: UserData;
    page:number;
    pageSize:number;
    includeTotalCount:boolean;
    recipientNodeSearch: string = "";
    menuItemSearch: string = "";


    constructor(userBean: UserBean, userdata: UserData, page: number = 0,
        pageSize: number = 0,
        includeTotalCount: boolean = false,
        recipientNodeSearch: string = "",
        menuItemSearch: string = "") {
        this.userBean = userBean;
        this.userData = userdata;
        this.page = page;
        this.pageSize = pageSize;
        this.includeTotalCount = includeTotalCount;
        this.recipientNodeSearch = recipientNodeSearch;
        this.menuItemSearch = menuItemSearch;

    }
}
