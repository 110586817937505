import { Injectable } from '@angular/core';
import { MenuItem } from '../models/menu-item';
import { Observable } from 'rxjs';
import { HttpCoreService } from './http-core.service';
import { UserBean } from '../models/userbean';
import { NavigationStateService } from './navigation-state.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class MenuitemService {

  menuItem: MenuItem;

  private selectMasterMenuItemComponentData: any = {
    searchData: {
      exactSearch: false,
      filter: '',
      prdClassList: [],
      prdStatusList: [],
      originalFilter: ''
    },
    searchResult: [],
    selectedMenuItems: []
  }

  constructor(public httpCoreService: HttpCoreService, private router: Router, private navigationStateService: NavigationStateService) { }

  public getMenuList(apiService, data): Observable<MenuItem[]> {
		console.log("getMenuItemListService Called"); 
    return this.httpCoreService.getHttpUrl(apiService, data, 'post');
  }
  
  public loadLookups(userBean: UserBean): Observable<any> {
    return this.httpCoreService.getHttpUrl('loadLookup', userBean, 'post');
  }

  public getMenuItemAutoSuggest(marketId: number, searchText: string, exactMatch: boolean): Observable<any> {
    let requestData = {
      marketId: marketId,
      searchText: searchText,
      exactMatch: exactMatch
    };
    return this.httpCoreService.getHttpUrl('getMenuItemListAutoSuggestion', requestData, 'post');
  }

  /* Search Data structure TODO: Make it type
  {
    "exactSearch": false,
    "filter": "ham",
    "prdClassList": [
      {
        "dsplOrdFlg": null,
         "entryCode": "9", 
         "entryOrder": 9, 
         "lexId":704, 
         "translatedValue": "CHECK CATEGORY"
      }
      ],
    "prdStatusList": [
      {
        dsplOrdFlg":null, 
        "entryCode":"1", 
        "entryOrder":1, 
        "lexId":646, 
        "translatedValue":"Approved"
      }
    ]
  }
  */
  public getMasterMIList(searchData: any, userBean: UserBean): Observable<any> {

    if(!searchData.prdStatusList || searchData.prdStatusList.length == 0 || searchData.prdStatusList.length == 2) {
      searchData.prdStatusList = [{
        entryCode: 2
      }]
    }

    let requestData : any = {
      searchOption: searchData.exactSearch ? 3 : 2, 
      approvalStatus: searchData.prdStatusList.map(as => as.entryCode).join(','),
      userBean: userBean
    };

    let miClass = searchData.prdClassList.map(pc => pc.entryCode).join(',');

    if(miClass != '') {
      requestData.miClass = miClass;
    }

    if(searchData.filter != '') {
      requestData.searchText= searchData.filter;
    }
    return this.httpCoreService.getHttpUrl('getMenuItem', requestData, 'post'); 
  }


  public getMenuItemAssociatinReport(menuItem: any, userBean: UserBean): Observable<any> {
    let requestData : any = {
      productId: menuItem.menuItemId,
      productCode: menuItem.menuItemCode,
      productName: menuItem.menuItemNa,
      userBean: userBean
    };

    return this.httpCoreService.getHttpUrl('getMMIDAssociationDetails', requestData, 'post'); 
  }

  public getMIDeleteReviewData(menuItem: any, userBean: UserBean): Observable<any> {
    //change the menu item object
    let menuItems = menuItem.map(menu => {
      return {
        menuItemId: menu.menuItemId,
        menuItemCode: menu.menuItemCode,
        menuItemName: menu.menuItemName,
        disabled: menu.disabled,
        menuItemClass: menu.menuItemClass,
        menuItemStatus: menu.menuItemStatus,
        menuItemSavedAsPartialStatus: menu.menuItemSavedAsPartialStatus,
        checked: menu.selected,
        secondaryItem: menu.secondaryMenuItemValue
      }
    });

    let requestData = {
      menuItemDeleteList: menuItems,
      userBean: userBean
    }

    return this.httpCoreService.getHttpUrl('getMenuItemReport', requestData, 'post');
  }

  public deleteMenuItem(menuItemList: any, userBean: UserBean , selectedMediaIds:any, api: string): Observable<any> {
    const menuItemIdList = [];
    menuItemList.forEach(item => {
			if(item.selected) {
        menuItemIdList.push(item.menuItemId);
			}
		});
    
    let requestData = {
      menuItemId: menuItemIdList,
      userBean: userBean,
      operation: [] ,
      selectedMediaIds: selectedMediaIds
    }
    return this.httpCoreService.getHttpUrl(api, requestData, 'post');
   
  }
  
  public checkMenuItemDeleteInability(selectedMIIds: number[], userBean: UserBean): Observable<any> {

    let requestData = {
      menuItemId: selectedMIIds,
      userBean: userBean,
      operation: [] ,
      selectedMediaIds: []
    }
    
    return this.httpCoreService.getHttpUrl('checkMIDeleteInabilities', requestData, 'post');
  }

  public getMediaFileList(menuItemList: any, userBean: UserBean): Observable<any> {       
    let requestData = {
      menuItemId: menuItemList,
      userBean: userBean,
      operation: []
    }
		return this.httpCoreService.getHttpUrl('getMediaForDeletion', requestData, 'post');
  }
  
  public deleteMenuItemsAsyncAPICall(menuItemId: any, userBean: UserBean, api: string) {
    let selectedMediaIds: any;
    let menuItemConfirmationList: Array<any> = [];
    this.deleteMenuItem(menuItemId, userBean, selectedMediaIds, api).subscribe(resp => {
      let data = resp;
      data.forEach(item => {
        const tempObject = {
          menuItemCode: '',
          menuItemName: '',
          result: '',
          errors: []
        };
        tempObject.menuItemCode = item.number;
        tempObject.menuItemName = item.name;
        tempObject.result = item.result;
        item.errors.forEach(it => {
          tempObject.errors.push(it);
        });
        menuItemConfirmationList.push(tempObject);
      });
      menuItemConfirmationList = menuItemConfirmationList.sort((a, b) => a.menuItemCode - b.menuItemCode);
      let navId = this.navigationStateService.saveNavigationState(menuItemConfirmationList);
      let booleanAsync = true;
      this.router.navigate(['/mass-mi-delete/delete-confirm', { navId: navId, booleanAsync: booleanAsync }]);
      this.startDeleteAsyncProcess(userBean, 'startDeleteAsyncProcess');
    });
    return menuItemConfirmationList;
  }
  
  public updateSelectMasterMenuItemComponentData(data: any) {
    this.selectMasterMenuItemComponentData = Object.assign(this.selectMasterMenuItemComponentData, data);
  }

  public getSelectMasterMenuItemComponentData() {
    return this.selectMasterMenuItemComponentData;
  }

  public startDeleteAsyncProcess(userBean: UserBean, api): void {
    const requestData = {
      userBean: userBean,
      operation: []
    }
    this.httpCoreService.getHttpUrl(api, requestData, 'post').subscribe();
  }
}
