import {UserBean} from '../models/userbean';

export class MenuItemSearch {
productId: number;
productCode: number;
productName: string;
productStatus: string;
productClass: string;
searchText: string;
familyGroup: number;
approvalStatus: string;
savedAsPartial: string;
searchOption: number;
checked: boolean;
userBean: UserBean;
miClass: string; }